import React, { Component } from "react";
import {FiActivity, FiHeart, FiUsers} from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'Discovers of solutions',
        description: 'We love to create groundbreaking Web3 projects using the latest technologies.'
    },
    {
        icon: <FiHeart />,
        title: 'Design & Creative',
        description: 'Today you can\'t do without modern design. Our experts will do everything to make the users of your site delighted.'
    },
    {
        icon: <FiUsers />,
        title: 'App Development',
        description: 'What could be better than good developers? Certified engineers with many years of experience.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
