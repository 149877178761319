import React, {Component} from "react";
import {Link} from 'react-router-dom';

const logoUrl = <img src="/assets/images/logo/logo.svg" alt="Digital Agency" />;

class Footer extends Component {
    constructor(props) {
        super(props);
        this.portfolioRef = this.props.portfolioRef
        this.contactRef = this.props.contactRef
    }

    render() {
        return (
          <React.Fragment>
              <footer className="footer-area footer-style-01 bg_color--6">
                  {/* Start Call to Action Area  */}
                  <div className="im-call-to-action-area ptb--70 im-separator">
                      <div className="container">
                          <div className="row align-items-center">
                              <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                  <div className="inner">
                                      <h2 className="text-white mb--0">Enough Talk, Let's Build Something Together</h2>
                                  </div>
                              </div>
                              <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                                  <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                                      <a className="btn-default btn-large btn-border btn-opacity"
                                         href="mailto:hello@aftermiracle.com">Contact us</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* End Call to Action Area  */}

                  {/* Start Footer Area  */}
                  <div className="footer-wrapper ptb--70">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                  <div className="ft-text">
                                      <div className="logo">
                                          <Link to="/">
                                              {logoUrl}
                                          </Link>
                                      </div>
                                      <p>Copyright © 2022
                                          <Link to="/"> AfterMiracle</Link>
                                          <br />
                                          <hr />
                                          <span>All rights reserved.</span>
                                      </p>
                                  </div>
                              </div>

                              {/* Start Single Widget  */}
                              <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                  <div className="footer-link">
                                      <h4>Company</h4>
                                      <ul className="ft-link">
                                          <li onClick={() => this.props.portfolioRef.current.scrollIntoView({behavior: "smooth"})}><Link>Portfolio</Link></li>
                                          <li onClick={() => this.props.contactRef.current.scrollIntoView({behavior: "smooth"})}><Link>Contact</Link></li>
                                      </ul>
                                  </div>
                              </div>
                              {/* End Single Widget  */}

                              {/* Start Single Widget  */}
                              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                                  <div className="footer-link">
                                      <h4>Say Hello</h4>
                                      <ul className="ft-link">
                                          <li>Email: <a href="mailto:hello@aftermiracle.com">hello@aftermiracle.com</a>
                                          </li>
                                          <li>Telegram: <a href="https://t.me/aftermiracle">@aftermiracle</a></li>
                                      </ul>
                                  </div>
                              </div>
                              {/* End Single Widget  */}
                          </div>
                      </div>
                  </div>
                  {/* End Footer Area  */}
              </footer>
          </React.Fragment>
        )
    }
}

export default Footer;
