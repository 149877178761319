import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/stack/image 1.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 2.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 3.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 4.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 5.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 6.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 7.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 8.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 9.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 10.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 11.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 12.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 13.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 14.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/stack/image 15.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
