import React, {Component} from "react";
import {Link} from 'react-router-dom';

const Portfolio_image = <img loading="lazy" src="/assets/images/portfolio/portfolio-1.webp" alt="React Creative Agency" />;
const Portfolio_image2 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-2.webp" alt="React Creative Agency" />;
const Portfolio_image3 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-3.webp" alt="React Creative Agency" />;
const Portfolio_image4 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-4.webp" alt="React Creative Agency" />;
const Portfolio_image5 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-5.webp" alt="React Creative Agency" />;
const Portfolio_image6 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-6.webp" alt="React Creative Agency" />;
const Portfolio_image7 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-7.webp" alt="React Creative Agency" />;
const Portfolio_image8 = <img loading="lazy"  src="/assets/images/portfolio/portfolio-8.webp" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Web3',
        title: 'DeFi invest platform',
        description: 'The service tokenizes the NFTs and allows users to jointly own and manage assets.'
    },
    {
        image: Portfolio_image2,
        category: 'Web3',
        title: 'NFT-marketplace',
        description: 'A decentralized cross-chain platform that allows to issue and sell ownership rights to digital works of art (NFTs).'
    },
    {
        image: Portfolio_image3,
        category: 'Web3',
        title: 'DeSoc family ecosystem',
        description: 'The project is an innovative Web3 ecosystem designed to help families overcome difficulties, make better choices, grow and achieve more together.'
    },
    {
        image: Portfolio_image4,
        category: 'Web3',
        title: 'Financial reputation protocol',
        description: 'A system for scoring a participant\'s reputation based on data on DeFi protocols and TradFi institutes.'
    },
    {
        image: Portfolio_image5,
        category: 'B2B',
        title: 'Dental products aggregator',
        description: 'The service opens up the opportunity to intelligently analyze suppliers and make the best decisions.'
    },
    {
        image: Portfolio_image6,
        category: 'Wiki',
        title: 'Emoji catalog',
        description: 'Full collection of Emojis for iOS, Android and other devices.'
    },
    {
        image: Portfolio_image7,
        category: 'Wiki',
        title: 'Stickers catalog',
        description: 'A fast and efficient catalog containing thousands of different stickers.'
    },
    {
        image: Portfolio_image8,
        category: 'Tools',
        title: 'Meta SEO inspector',
        description: 'The service checks the web page for compliance with the latest SEO standards and recommendations.'
    }
]

class Portfolio extends Component {
    render() {
        let title = 'Some of our Recent Works',
          description = 'Here you will find wonderful projects that have already been launched and gained their first users.';
        return (
          <React.Fragment>
              <div className="portfolio-wrapper">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-12">
                              <div className="section-title text-center">
                                  <span className="subtitle">Our works</span>
                                  <h2 className="title">{title}</h2>
                                  <p className="description" dangerouslySetInnerHTML={{__html: description}}></p>
                              </div>
                          </div>
                      </div>
                      <div className="row mt--30">
                          {PortfolioList.map((value, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                <div className="im_portfolio">
                                    <div className="thumbnail_inner">
                                        <div className="thumbnail">
                                            <Link>
                                                {value.image}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <div className="portfolio_heading">
                                                <div className="category_list">
                                                    <Link to="#">{value.category}</Link>
                                                </div>
                                                <h4 className="title"><Link>{value.title}</Link>
                                                </h4>
                                            </div>
                                            <div className="portfolio_hover">
                                                <p>{value.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Link className="transparent_link" />
                                </div>
                            </div>
                          ))}
                      </div>
                  </div>

              </div>
          </React.Fragment>
        )
    }
}

export default Portfolio;
