import React, {Component} from "react";
import {FiBox, FiCheckSquare, FiCode, FiCodesandbox, FiEdit, FiMonitor} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Web Development',
        description: 'Building custom web applications is our forte, whether it be a web-based software application, an ERP, an eCommerce website or a business website.'
    },
    {
        icon: <FiBox />,
        title: 'Blockchain Development',
        description: 'Our blockchain development services include DeFi, NFT, dApp, Smart Contracts, and custom applications that are based on blockchain technology.'
    },
    {
        icon: <FiEdit />,
        title: 'UI/UX Design',
        description: 'We prototype, come up with branding, build UI/UX Design for web, mobile and other platforms. Conduct UX interviews to make sure the design is user-friendly.'
    },
    {
        icon: <FiCheckSquare />,
        title: 'Quality Assurance',
        description: 'We monitor the identification of possible errors, predicting incredible user behavior, creating tests and conducting comprehensive manual and automated testing.'
    },
    {
        icon: <FiCode />,
        title: 'Software DevOps',
        description: 'Our qualified DevOps engineers effectively manage the workflow between operations and development to ensure smooth execution throughout the software lifecycle.'
    },
    {
        icon: <FiCodesandbox />,
        title: 'AI/ML',
        description: 'We help to rebuild digital processes by integrating technologies such as artificial intelligence and machine learning into the software development process.'
    },
]

class ServiceTwo extends Component {
    render() {
        let title = 'Services provide for you',
          description = 'Bespoke solutions for your business',
          subtitle = 'What we can do for you';
        return (
          <React.Fragment>
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="section-title text-center">
                              <span className="subtitle">{subtitle}</span>
                              <h2 className="title">{title}</h2>
                              <p className="description" dangerouslySetInnerHTML={{__html: description}}></p>
                          </div>
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-12 col-12 mt--30">
                          <div className="row service-main-wrapper">
                              {ServiceList.map((val, i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2 text-left">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          </React.Fragment>
        )
    }
}

export default ServiceTwo;
