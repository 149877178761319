import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {FiMenu, FiX} from "react-icons/fi";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    render() {


        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const {logo, color = 'default-color'} = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        } else if (logo === 'dark') {
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo.svg" alt="Digital Agency" />;
        }

        return (
          <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
              <div className="header-wrapper" id="header-wrapper">
                  <div className="header-left">
                      <div className="logo">
                          <a href="/">
                              {logoUrl}
                          </a>
                      </div>
                  </div>
                  <div className="header-right">
                      <nav className="mainmenunav d-lg-block">
                          <ul className="mainmenu">
                              <li><Link onClick={() => this.props.aboutRef.current.scrollIntoView({behavior: "smooth"})}>About</Link></li>
                              <li><Link onClick={() => this.props.skillsRef.current.scrollIntoView({behavior: "smooth"})}>Skills</Link></li>
                              <li><Link onClick={() => this.props.portfolioRef.current.scrollIntoView({behavior: "smooth"})}>Portfolio</Link></li>
                              <li><Link onClick={() => this.props.contactRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})}>Contacts</Link></li>
                          </ul>
                      </nav>
                      {/* Start Humberger Menu  */}
                      <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                          <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                      </div>
                      {/* End Humberger Menu  */}
                      <div className="close-menu d-block d-lg-none">
                          <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                      </div>
                  </div>
              </div>

          </header>
        )
    }
}

export default Header;
