import React, {Component} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component {
  constructor() {
    super()
    this.aboutRef = React.createRef()
    this.skillsRef = React.createRef()
    this.portfolioRef = React.createRef()
    this.contactRef = React.createRef()
  }

  render() {
    return (
      <div className="active-dark">
        <Helmet pageTitle="AfterMiracle" />
        <Header aboutRef={this.aboutRef}
                skillsRef={this.skillsRef}
                portfolioRef={this.portfolioRef}
                contactRef={this.contactRef}
                headertransparent="header--transparent"
                colorblack="color--black"
                logoname="logo.svg"
        />

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <ServiceTwo />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area */}
        <div ref={this.skillsRef} className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">Our stack</span>
                  <h2 className="title">We work with the following technologies</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}


        {/* Start CounterUp Area */}
        <div ref={this.aboutRef} className="rn-counterup-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Experts growts</span>
                  <h2 className="title">Our Team Growth</h2>
                  <p className="description">We have grown strength over the past 3 years.</p>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Portfolio Area */}
        <div ref={this.portfolioRef} className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
        <div ref={this.contactRef}>
          <Footer
            portfolioRef={this.portfolioRef}
            contactRef={this.contactRef}
          />
        </div>
      </div>
    )
  }
}

export default MainDemo;
